import store from 'store';
const USER_KEY = 'user_key';
export default {
  /**
   * 保存
   * @param {*} user
   */
  savaUser(user) {
    store.set(USER_KEY, user);
  },

  /**
   * 读取
   * @returns
   */
  getUser() {
    return store.get(USER_KEY) || {};
  },

  /**
   * 删除
   */
  removeUser() {
    store.remove(USER_KEY);
  },
};
