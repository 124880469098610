import { SET_HEAD_TITLE, RECEIVE_USER, RESET_USER } from './action-types'
import { combineReducers } from 'redux'
import storageUtils from '../utils/storageUtils'

const initHeadTitle = '首页'
function headTitle(state = initHeadTitle, action) {
  switch (action.type) {
    case SET_HEAD_TITLE:
      return action.data
    default:
      return state
  }
}

/**
 * 管理用户信息状态
 */
const initUser = storageUtils.getUser()

function user(state = initUser, action) {
  switch (action.type) {
    case RECEIVE_USER:
      return action.user
    case RESET_USER:
      return {}
    default:
      return state
  }
}

export default combineReducers({
  headTitle,
  user,
})
